// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";

// Internal imports, e.g:
import { startRecording, createDownloadLink, stopRecording, init } from '../packs/init-web-audio-recorder';
// import '../packs/init-vimeo-player';
import NormalVideoPlayer from '../packs/init-video-player';
import VimeoVideoPlayer from '../packs/init-vimeo-player';

const isVimeoUrl = (url) => {
	const vimeoPattern = /https?:\/\/(www\.)?(vimeo\.com\/\d+|player\.vimeo\.com\/video\/\d+)/;
	return vimeoPattern.test(url);
};
 
document.addEventListener('turbolinks:load', () => {
	// recordButton.addEventListener("click", startRecording);
	// recordButton.addEventListener("click", init);
	if(isVimeoUrl(gon.js_exam_parts[0][0])) {
		document.querySelector("#exam_player_video").remove()
		VimeoVideoPlayer()
	}else {
		NormalVideoPlayer()
	}
	stopButton.addEventListener("click", stopRecording);
	// exam_start_recording.addEventListener("click", startRecording);
	exam_start_recording.addEventListener("click", init);
	// exam_end_recording.addEventListener("click", stopRecording);

});

