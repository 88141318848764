URL = window.URL || window.webkitURL;
var gumStream; 						//stream from getUserMedia()
var recorder; 						//WebAudioRecorder object
var input="hello"; 							//MediaStreamAudioSourceNode  we'll be recording
var encodeAfterRecord = true;       // when to encode
// shim for AudioContext when it's not avb. 
var AudioContext = window.AudioContext || window.webkitAudioContext;
var audioContext; //new audio context to help us record
var recordButton = document.getElementById("recordButton");
var stopButton = document.getElementById("stopButton");
var recording_icon = document.getElementById("recording_icon");
var exam_start_button = document.getElementById("start_button");



import JSZip from "jszip";
import upload_audio from "./audio_uploader";




//add events to those 2 buttons

var records = []
var download_act = false;

var index = 0;

function startRecording() {
	console.log("startRecording() called");
	recording_icon.classList.add("fa-blink");
	

	/*
		Simple constraints object, for more advanced features see
		https://addpipe.com/blog/audio-constraints-getusermedia/
	*/
    
    var constraints = { audio: true, video:false }

    /*
    	We're using the standard promise based getUserMedia() 
    	https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
	*/

	navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
		console.log("getUserMedia() success, stream created, initializing WebAudioRecorder...");

		/*
			create an audio context after getUserMedia is called
			sampleRate might change after getUserMedia is called, like it does on macOS when recording through AirPods
			the sampleRate defaults to the one set in your OS for your playback device

		*/
		audioContext = new AudioContext();

		//assign to gumStream for later use
		gumStream = stream;
		
		/* use the stream */
		input = audioContext.createMediaStreamSource(stream);
		
		//stop the input from playing back through the speakers
		//input.connect(audioContext.destination)

		recorder = new WebAudioRecorder(input, {
		  workerDir: "../web-audio-recorder-js/lib-minified/", // must end with slash
		  encoding: "mp3",
		  numChannels:2, //2 is the default, mp3 encoding supports only 2
		  onEncoderLoading: function(recorder, encoding) {
		    // show "loading encoder..." display
		    console.log("Loading "+encoding+" encoder...");
		  },
		  onEncoderLoaded: function(recorder, encoding) {
		    // hide "loading encoder..." display
		    console.log(encoding+" encoder loaded");
		  }
		});

		recorder.onComplete = function(recorder, blob) { 
			records.push({blob: blob, enc: recorder.encoding, index: index})
			console.log("Records", records);
			// if(index > 0){
				upload_audio({blob: blob, enc: recorder.encoding}, index, download_act)
			// }
			index++
			if(download_act){
				// createDownloadLink(blob,recorder.encoding);
				console.log("Encoding complete");
			}
		}

		recorder.setOptions({
		  timeLimit:3000,
		  encodeAfterRecord:encodeAfterRecord,
	      mp3: {bitRate: 160}
	    });

		//start the recording process
		recorder.startRecording();
		console.log("Recording started");
		 

	}).catch(function(err) {
	  	//enable the record button if getUSerMedia() fails
    	recordButton.disabled = false;
    	stopButton.disabled = true;

	});

	function minSecStr(n) {
    	return (n < 10 ? "0" : "") + n;
 	};

	var timeDisplay = document.getElementById("time-display");
	function updateDateTime(){
		// var sec;
		// $dateTime.html((new Date).toString());
		var sec = recorder.recordingTime() | 0;
		console.log(("" + (minSecStr(sec / 60 | 0)) + ":" + (minSecStr(sec % 60))))
		timeDisplay.innerHTML=("" + (minSecStr(sec / 60 | 0)) + ":" + (minSecStr(sec % 60)));
	};

  	window.setInterval(updateDateTime, 1000);

	

	//disable the record button
    recordButton.disabled = true;
    stopButton.disabled = false;
	recordButton.innerHTML = "録音中"
	exam_start_button.disabled = false;
}

function stopRecording() {
	console.log("stopRecording() called");
	recording_icon.classList.remove("fa-blink");

	//stop microphone access
	gumStream.getAudioTracks()[0].stop();

	//disable the stop button
	stopButton.disabled = true;
	recordButton.disabled = false;
	
	//tell the recorder to finish the recording (stop recording + encode the recorded audio)
	download_act = true; // to be able to create the downaload able file
	recorder.finishRecording();

	console.log('Recording stopped');
}

let prev_stopped = false
let timeCheckInterval;

function stopPrevRecording(){
	console.log("stopRecording() called"); 
	recording_icon.classList.remove("fa-blink");

	prev_stopped = false

	//stop microphone access
	// if(index > 0){
	// 	gumStream.getAudioTracks()[0].stop();
	// }else {
	// 	index = 1
	// 	prev_stopped = true
	// 	return
	// }
	if(gumStream){
		if(gumStream.getAudioTracks()[0]){
			gumStream.getAudioTracks()[0].stop();
		}
	}

	//disable the stop button
	stopButton.disabled = true;
	recordButton.disabled = false;
	
	//tell the recorder to finish the recording (stop recording + encode the recorded audio)
	// 5 seconds buffer time 
	setTimeout(() => {
		if(!prev_stopped && recorder){
			prev_stopped = true
			download_act = false; 
			recorder.finishRecording();
			console.log('prev record stopped');
		}
	}, 1000);
}

function startNewRecording(duration) {

	// const start_recoring = () => {
	// 	startRecording()
	// }
	startRecording()

	// var currentTime = 0
	// var recordingStarted = false;
	// if(duration){
	// 	console.log("DURATION GOES HERE");
	// 	timeCheckInterval = setInterval(function () {
	// 		if (duration - currentTime <= 125 && !recordingStarted) {
	// 			if(!prev_stopped && recorder?.finishRecording()){
	// 				prev_stopped = true
	// 				download_act = false; 
	// 				recorder.finishRecording();
	// 				console.log('prev record stopped');
	// 			}
	// 			start_recoring();
	// 			recordingStarted = true;
	// 		}
	// 		currentTime++
	// 		console.log("CURRENT TIME "+ currentTime, "BEFORE RECORDING");
	// 	}, 1000);
	// }else {
	// 	startRecording()
	// }

}

 
function createDownloadLink() {


	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();

	
	const zip = new JSZip();

	const folder_name = `${gon.js_exam_name}-${month}-${day}`
	const exam_json = gon.js_exam_parts;

	// for(let i = 0; i < records.length; i++){
	// 	const audio_name = exam_json[i+1][1] ?? "part "+index +".mp3"
	// 	zip.file(audio_name, records[i].blob, { binary: true });
	// }

	for (let i = 0; i < records.length; i++) {

		const index = records[i]?.index
		// const partName = exam_json[index] ? exam_json[index][1] : "Part "+(index+1);
		const partName = "Part "+(index+1)
		const audio_name = partName && partName != "" ? partName+".mp3" : "part "+(index+1)+".mp3"
		zip.file(audio_name, records[i].blob, { binary: true });
	}
	

	zip.generateAsync({ type: 'blob' })
		.then(function (content) {

			var url = URL.createObjectURL(content);

			// Create a link element for downloading the ZIP
			var link = document.createElement('a');
			link.href = url;
			link.download = `${folder_name}.zip`;
			// link.textContent = `Download ${folder_name}.zip`;
			link.textContent = "Click here to download files"
		
			// Add classes to the link (if needed)
			link.classList.add('modal-button');
			link.classList.add('btn');
			link.classList.add('btn-primary');
		
			// Append the link to your desired element
			var modal_recording_list = document.getElementById("modal_recording_list");
			modal_recording_list.innerHTML = ''; // Clear any previous content
			modal_recording_list.appendChild(link);
		})
		.catch(function (error) {
			console.error('Error generating ZIP:', error);
		});
	
	// const combinedBlob = new Blob(records.map(record => record.blob), { type: records[0].blob.type });

	// var url = URL.createObjectURL(combinedBlob);
	// // var au = document.createElement('audio');
	// var li = document.createElement('li');
	// var link = document.createElement('a');

	// //add controls to the <audio> element
	// // au.controls = true;
	// // au.src = url;

	// // //link the a element to the blob
	// link.href = url;
	// link.download = folder_name+".zip";
	// link.classList.add('modal-button');
	// link.classList.add('btn');
	// link.classList.add('btn-primary');


	// link.innerHTML = link.download;

	// //add the new audio and a elements to the li element
	// // li.appendChild(au);
	// li.appendChild(link);

	// //add the li element to the ordered list
	// // recordingsList.appendChild(li);
	// var modal_recording_list = document.getElementById("modal_recording_list");
	// modal_recording_list.appendChild(li);
}



window.addEventListener('load', (event) => {
  console.log('page is fully loaded');
});


class AudioVisualizer {
	constructor(audioContext, processFrame, processError) {
		this.audioContext = audioContext;
		this.processFrame = processFrame;
		this.connectStream = this.connectStream.bind(this);
		navigator.mediaDevices.getUserMedia({
				audio: true,
				video: false
			})
			.then(this.connectStream)
			.catch((error) => {
				if (processError) {
					processError(error);
				}
			});
	}
	connectStream(stream) {
		this.analyser = this.audioContext.createAnalyser();
		const source = this.audioContext.createMediaStreamSource(stream);
		source.connect(this.analyser);
		this.analyser.smoothingTimeConstant = 0.5;
		this.analyser.fftSize = 32;
		this.initRenderLoop(this.analyser);
	}
	initRenderLoop() {
		const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
		const processFrame = this.processFrame || (() => {});
		const renderFrame = () => {
			this.analyser.getByteFrequencyData(frequencyData);
			processFrame(frequencyData);
			requestAnimationFrame(renderFrame);
		};
		requestAnimationFrame(renderFrame);
	}
}
const visualMainElement = document.getElementById('audio_viz');
const visualValueCount = 16;
let visualElements;
const createDOMElements = () => {
	let i;
	for (i = 0; i < visualValueCount; ++i) {
		const elm = document.createElement('div');
		visualMainElement.appendChild(elm);
	}
	visualElements = document.querySelectorAll('main div');
};
// if(visualMainElement){
	createDOMElements();
// }
const init = () => {

	//disable the record button
    recordButton.disabled = true;
    stopButton.disabled = false;
	recordButton.innerHTML = "録音中"
	exam_start_button.disabled = false;

	// Creating initial DOM elements
	const audioContext = new AudioContext();
	const initDOM = () => {
		visualMainElement.innerHTML = '';
		createDOMElements();
	};
	initDOM();
	// Swapping values around for a better visual effect
	const dataMap = {
		0: 15,
		1: 10,
		2: 8,
		3: 9,
		4: 6,
		5: 5,
		6: 2,
		7: 1,
		8: 0,
		9: 4,
		10: 3,
		11: 7,
		12: 11,
		13: 12,
		14: 13,
		15: 14
	};
	const processFrame = (data) => {
		const values = Object.values(data);
		let i;
		for (i = 0; i < visualValueCount; ++i) {
			const value = values[dataMap[i]] / 255;
			const elmStyles = visualElements[i].style;
			elmStyles.transform = `scaleY( ${ value } )`;
			elmStyles.opacity = Math.max(.25, value);
		}
	};
	const processError = () => {
		visualMainElement.classList.add('error');
		visualMainElement.innerText = 'Please allow access to your microphone in order to see this demo.\nNothing bad is going to happen... hopefully :P';
	}
	const a = new AudioVisualizer(audioContext, processFrame, processError);
};


export { startRecording, createDownloadLink, stopRecording, init, stopPrevRecording, startNewRecording };
